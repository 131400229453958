const state = {
  router: [],
}

const mutations = {
  GET_DATA(state, data){
    state.router = data
  }
}

const actions = {
 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}