<template>
  <div class="Pagination">
    <template v-if="pageNum < 8">
      <div class="Pagination_item" :class="Number(currentPage) == i ? 'active':''" v-for="i in list" :key="i" @click="active(i)">{{ i }}</div>
    </template>
    <template v-else>
      <div class="Pagination_item" :class="Number(currentPage) == i ? 'active':''" v-for="i in TopThree" :key="i" @click="active(i)">{{ i }}</div>
      <span class="apostrophe"> …… </span>
      <div class="Pagination_item" :class="Number(currentPage) == i ? 'active':''" v-for="i in LastThree" :key="i" @click="active(i)">{{ i }}</div>
      <div class="skipbtn">
        <span>跳转到：</span>
        <el-input v-model="activeNun" @change="activeNunChange" />
        <div class="Pagination_item" @click="active(activeNun)">跳转</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props:{
    total: {
      type: Number,
      default: 0
    },
    currentpage: {
      type: Number,
      default: 1
    },
    pagesize: {
      type: Number,
      default: 20
    },
    // height: {
    //   type: String,
    //   default: '100%'
    // },
  },
  data() {
    return {
      activeNun: '',
      currentPage: 1,
      list: [],
      TopThree: [],
      LastThree: [],
      pageNum: 0,
    }
  },
  watch: {
    currentPage(){
      this.$emit('initData', this.currentPage)
    },
    total: {
      handler(newV, oldV) {
        if(newV) this.setTotal()
      }
    }
  },
  created() {
    this.setTotal()
  },
  methods: {
    active(val) {
      this.currentPage = Number(val);
    },
    setTotal() {
      if(!this.total) {
        this.list = [1];
        return
      }
      this.pageNum = Math.trunc(this.total / this.pagesize);
      if(this.total % this.pagesize !== 0) this.pageNum += 1;
      if(this.pageNum < 8) {
        this.list= [];
        for(let i = 0; i < this.pageNum; i++) this.list.push(i+1)
      } else {
        this.TopThree = [1, 2, 3];
        this.LastThree = [this.pageNum - 2, this.pageNum - 1, this.pageNum];
      }
    },
    activeNunChange(val) {
      if(Number(val) > this.pageNum) this.activeNun = this.pageNum + '';
    }
  }
}
</script>

<style lang="less" scoped>
.Pagination {
  // background-color: #3f74f9;
  width: 100%;
  background-color: #fff;
  padding: 17px;
  // padding: 10px;border-top: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #586086;
  &_item {
    width: 4vw;
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    font-size: 12px;
    box-sizing: border-box;
    border: 1px solid #ccdae7;
    border-radius: 5px;
    margin: 0 10px;
    cursor: pointer;
  }
  .apostrophe {
    padding-bottom: 10px;
    margin: 0 10px;
  }
  .skipbtn {
    display: flex;
    align-items: center;
    margin-left: 34px;
    span {
      font-size: 12px;
      color: #b2b7cd;
      margin-right: 10px;
    }
    ::v-deep .el-input__inner,.el-input {
      width: 70px;
      height: 50px;
      text-align: center;
    }
  }
}
.active {
  border: 1px solid #3f74f9;
  color: #3f74f9;
}
</style>