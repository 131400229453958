import instance from "@/utils/request"

// 登入
// export const Login = (data) => instance.post("/xwUser/Login", data);
export function Login(data) {
  return instance({
    url: `/xwUser/Login`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'post',
    data
  })
}
// 刷新token
export function refreshToken(data) {
  return instance({
    url: `/xwUser/refreshToken`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'post',
    data
  })
}