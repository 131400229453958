<template>
  <div class="btns">
      <el-button
        v-if="hasEdit"
        :disabled="editdisabled"
        type="text"
        :icon="EditIcon"
        size="small"
        :style="{ color: editColor }"
        @click="clickEditBtn"
      ><span :style="{ borderBottom: `1px solid ${editColor}` }">{{ editText }}</span></el-button>
      <el-button
        v-if="hasDel"
        :disabled="deldisabled"
        type="text" 
        :icon="DelIcon"  
        size="small"
        :style="{ color: delColor }"
        @click="clickDelBtn"
      ><span :style="{ borderBottom: `1px solid ${delColor}` }">{{ delText }}</span></el-button>
      <el-button
        v-if="hasDetail"
        type="text" 
        :icon="DetailIcon"  
        size="small"
        :style="{ color: detailColor }"
        @click="clickDetailBtn"
      ><span :style="{ borderBottom: `1px solid ${detailColor}` }">{{ detailText }}</span></el-button>
      <slot />
  </div>
</template>
<script>
export default {
  name:"Button-index",
  props: {
    // icon
    EditIcon: {
      type: String,
      default: 'el-icon-edit'
    },
    DelIcon: {
      type: String,
      default: 'el-icon-delete'
    },
    DetailIcon: {
      type: String,
      default: 'el-icon-search'
    },
    // color
    editColor: {
      type: String,
      default: '#3f74f9'
    },
    delColor: {
      type: String,
      default: '#f00'
    },
    detailColor: {
      type: String,
      default: '#3f74f9'
    },
    // text
    editText: {
      type: String,
      default: '编辑'
    },
    delText: {
      type: String,
      default: '删除'
    },
    detailText: {
      type: String,
      default: '详情'
    },
    // isShow
    hasEdit: {
      type: Boolean,
      default: true
    },
    hasDel: {
      type: Boolean,
      default: true
    },
    hasDetail: {
      type: Boolean,
      default: false
    },
    editdisabled: {
      type: Boolean,
      default: false
    },
    deldisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  methods: {
    clickEditBtn() {
      this.$emit('Edit')
    },
    clickDelBtn() {
      this.$emit('Del')
    },
    clickDetailBtn() {
      this.$emit('Detail')
    },
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-button {
  span {
    // text-decoration:underline;
    border-bottom: 1px solid #000;
  }
}
</style>