import Vue from 'vue';
import {
  Button,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  FormItem,
  Input,
  Form,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Table,
  TableColumn,
  Pagination,
  Loading,
  Dialog,
  Radio,
  Message,
  Empty,
  MessageBox,
  Switch,
  DatePicker,
  Upload,
  Select,
  Option,
  Tag,
  radioGroup,
  Tooltip,
  Tree,
} from 'element-ui';

const element = {
  install() {
    Vue.use(Button);
    Vue.use(Menu);
    Vue.use(Submenu);
    Vue.use(MenuItemGroup);
    Vue.use(MenuItem);
    Vue.use(FormItem);
    Vue.use(Input);
    Vue.use(Form);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Pagination);
    Vue.use(Loading);
    Vue.use(Dialog);
    Vue.use(Radio);
    Vue.use(Empty);
    Vue.use(Switch);
    Vue.use(DatePicker);
    Vue.use(Upload);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(Tag);
    Vue.use(Tooltip);
    Vue.use(radioGroup);
    Vue.use(Tree);
    Vue.prototype.$message = Message;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
  }
}

export default element