import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'reset-css';
import store from './store'

import 'element-ui/lib/theme-chalk/index.css';
import element from '@/ElementUi/index';

import "@/assets/icon/iconfont.css"
import components from '@/components/index';
import '@/assets/less/index.less'
import '@/assets/less/setelstyle.less'

Vue.use(element);
Vue.use(components);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 添加实例属性
Object.assign(Vue.prototype, {
  formValidate: require('./utils/formValidate').default, // 表单验证
})