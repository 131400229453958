<template>
  <div class="upload">
    <el-upload
      action="#"
      :http-request="httpRequest"
      list-type="picture-card"
      :on-preview="handlePictureCardPreview"
      :file-list="fileList"
      :on-remove="handleRemove">
      <i class="el-icon-plus"></i>
    </el-upload>
    <Dialog
      v-if="dialogVisible"
      append-to-body
      viewType="detail"
      min-width="10px"
      @close="closeImg"
      icon="el-icon-picture-outline"
      title="查看图片"
    >
      <div class="img_box">
        <img :src="dialogImageUrl" alt="">
      </div>
    </Dialog>
  </div>
</template>

<script>
import { upload } from '@/api'
export default {
  neme: 'upload_index',
  props: {
    fileList: {
      type: Array,
      default: () => []
    }, 
  },
  data() {
    return {
      dialogVisible: false,
      dialogImageUrl: '',
      // fileList: [],
      imgUrl: ''
    }
  },
  created() {

  },
  methods: {
    httpRequest({ file }) {
      let reader = new FileReader()
      reader.readAsDataURL(file) //读取文件并将文件以URL的形式保存在resulr属性中 base64格式
      reader.onload = (e) => { // 文件读取完成时触发
        let result = e.target.result // base64格式图片地址
        var image = new Image()
        image.src = result // 设置image的地址为base64的地址
        image.onload = async() => { // 图片加载完成后才能进行压缩处理，从而转换为base64 进行赋值
          let width = image.width // 图片宽度
          let height = image.height
          let dataUrl = this.compress(image, width, height, 0.7)
          const _file = this.base64ToFile(dataUrl, file.name);
          const formData = new FormData();
          formData.append('file', _file);
          let { code, data } = await upload(formData)
          if(code == 200) this.$emit('getImgUrl', data.url);
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove({ url }) {
     this.$emit('remove', url);
    },
    closeImg() {
      this.dialogVisible = false;
    },
    // base64转文件
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--) ia[n] = bytes.charCodeAt(n);
      return new File([ia], fileName, { type: mime });
    },
    /*
    * 图片压缩
    * img   原始图片
    * width  压缩后的宽度
    * height  压缩后的高度
    * ratio  压缩比率
    */
    compress (img, width, height, ratio) {
      let canvas, ctx, img64;
      canvas = document.createElement('canvas')
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      img64 = canvas.toDataURL("image/jpeg", ratio);
      return img64;
    }
  }
}
</script>

<style lang="less" scoped>
.upload {

}
.img_box {
  width: 100%;
  height: 66vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>