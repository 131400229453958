const state = {
  routerList: [],
}

const mutations = {
  SET_ROUTERLIST(state, data) {
    state.routerList = data
  }
}

const actions = {
 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}