import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { reuterData } from "@/router/routerData.js"

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'loginindex',
    component: () => import(/* webpackChunkName: "login" */ '../views/login'),
    meta: {
      title: '登入'
    }
  },
  {
    path: '/',
    name: 'layout',
    redirect:"/map",
    component: () => import(/* webpackChunkName: "layout" */ '../layout'),
  },
  // {
  //   path: '/appmap',
  //   name: 'appmap',
  //   component: () => import(/* webpackChunkName: "layout" */ '../views/appmap'),
  //   meta: {
  //     title: '地图'
  //   }
  // },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404'),
    meta: {
      title: '404'
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})
// 路由前置守卫 -- （进入路由时会执行）
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('save-token');
  document.title = to.path == '/login' ? '信息标注':`${to.meta.title} - 信息标注` // 修改网页标题
  if(to.path === "/login" && token){
    next("/");
    return
  }
  if(to.path !== "/login" && !token){
    next("/login")
    return
  }
  if(token && store && !store.state.NaeBer.routerList.length){
    const userData = JSON.parse(localStorage.getItem('save-userInfo')) || {};
    let accountType = 0
    if(userData.username == 'admin') accountType = 1
    else accountType = userData.userextend ? userData.userextend.accounttypeid : 0;
   if(accountType == 1) {
      reuterData.forEach(item => router.addRoute("layout", item));
      store.commit('NaeBer/SET_ROUTERLIST', reuterData);
    } else if(accountType == 2) {
      let newList = reuterData.filter(item => {
        return item.name != 'AccountManag'
      });
      newList.forEach(item => router.addRoute("layout", item));
      store.commit('NaeBer/SET_ROUTERLIST', newList);
    } else if(accountType == 3) {
      let newList = reuterData.filter(item => {
        return item.name == 'map' || item.name == 'MarkerManag';
      });
      newList.forEach(item => router.addRoute("layout", item));
      store.commit('NaeBer/SET_ROUTERLIST', newList);
    }
    next(to.path);
    return
  }
  next()
})
export default router
