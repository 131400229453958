<template>
 <el-dialog
  :title="null"
  :visible.sync="Visible"
  :width="width"
  :before-close="handleClose"
  append-to-body
  :close-on-click-modal="clickmodal"
  :show-close="showclose"
  center
>
  <div slot="title" class="dialog-title">
       <el-button type="primary" :icon="icon" circle size="mini"></el-button>
      <span>{{ title }}</span>
    </div>
  <slot />
  <span slot="footer" class="dialog-footer" v-if="AcquiesceBtn">
    <el-button @click="handleClose" v-if="CancelBtn">取 消</el-button>
    <el-button v-show="!viewType" type="primary" @click="Submit" :loading="SubmitLoading">{{ SubmitText }}</el-button>
  </span>
</el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props:{
    title: {
      type: String,
      default: '提示'
    },
    width: {
      type: String,
      default: '50%'
    },
    icon: {
      type: String,
      default: 'el-icon-user'
    },
    SubmitLoading: {
      type: Boolean,
      default: false
    },
    viewType: {
      type: String,
      default: ''
    },
    AcquiesceBtn: {
      type: Boolean,
      default: true
    },
    clickmodal: {
      type: Boolean,
      default: false
    },
    SubmitText: {
      type: String,
      default: '确 定'
    },
    CancelBtn: {
      type: Boolean,
      default: true
    },
    showclose: {
      type: Boolean,
      default: true
    },
  },
  created() {
  },
  data() {
    return {
      Visible: true,
    }
  },
  methods: {
    Submit() {
      this.$emit('Submit')
    },
    handleClose() {
      this.$emit('close');
    },
  }
}
</script>

<style lang="less" scoped>
.dialog-title {
  span {
    margin-left: 5px;
  }
}
::v-deep .el-dialog {
  transform: translate(-50%,-50%);
  left: 50%;
  top:50%;
  margin: 0 !important;
  border-radius: 5px;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #dbe5ee;
  //  box-sizing: border-box;
}
::v-deep .el-popup-parent--hidden {
  padding-right: 0 !important;
}
</style>