
const state = {

}

const mutations = {
  SET_INITDATA() {
    
  }
}

const actions = {
  actions_initdata({ commit }) {
    commit('SET_INITDATA')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}