
const state = {
  userInfo: null || JSON.parse(localStorage.getItem('save-userInfo')),
}

const mutations = {
  GET_USERINFO(state, userInfo) {
    state.userInfo = userInfo
  }
}

const actions = {
 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}