import instance from "@/utils/request"

// 
// 上传图片
export const upload = (data) => instance.post("/xwFileUpload/upload", data);

// 综合查询
export const colligatesearch = (params) => instance.get("/xwDxCoordinate/colligatesearch", { params });

// 根据系统编号获取地图id
export const getmapid = (params) => instance.get("/xwDxMap/getmapid", { params });

// 获取execl导出数据
export const outExcel = (params) => instance.get("/xwDxCoordinate/outExcel", { params });

// export function Login(data) {
//   return instance({
//     url: `/xwUser/Login`,
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//     method: 'post',
//     data
//   })
// }