<template>
  <el-table
    :data="data"
    style="width: 100%"
    size="small"
    border
    v-bind="$attrs"
    v-on="$listeners"
  >
    <el-table-column type="selection" width="50" v-if="hasC" align="center"/>
    <el-table-column type="index" align="center" width="50"  label="序号"/>
    <slot />
    <template slot="empty">
      <el-empty description="暂无数据" :image-size="120"></el-empty>
    </template>
  </el-table>
</template>

<script>
export default {
  name: 'new_table',
  props:{
    data: {
      type: Array,
      default: () => []
    }, 
    height: {
      type: String,
      default: '100%'
    },
    hasC: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {

    }
  },
  methods: {
   
  }
}
</script>

<style lang="less" scoped>
// .table {
//   width: 100%;
//   height: auto !important;
// }
// ::v-deep .table_header {
//   background-color: #f5f9ff !important;
// }
::v-deep .el-table__header-wrapper {
  width: 100% !important;
}
::v-deep .el-table__body-wrapper {
  width: 100%;
  height: 59vh !important;
  overflow-y: auto !important;
}
::v-deep .cell {
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
</style>

<style>
.el-table .warning-row {
  background: #f9fbff;
}
.el-table .success-row {
  background: #f0f9eb;
}
</style>