const state = {
  isfold: false,
}

const mutations = {
  SET_ISFOLD(state, data) {
    state.isfold = data
  }
}

const actions = {
 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}