const state = {
  punctuation: null
}

const mutations = {
  SET_PUNCTUATION(state, data) {
    state.punctuation = data;
  }
}

const actions = {
 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}