<template>
  <div class="btn" @click="ClickFn">
      <el-button :type="type" :icon="icon" circle  :size="size" />
      <span>{{ text }}</span>
  </div>
</template>
<script>
export default {
  name:"Button-index",
  props: {
    icon: {
      type: String,
      default: 'el-icon-edit'
    },
    text: {
      type: String,
      default: '大苏打'
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'mini'
    },
  },
  data() {
    return {}
  },
  methods: {
    ClickFn() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="less" scoped>
.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 10px;
  span {
    font-size: 14px;
    margin-left: 5px;
  }
}
</style>