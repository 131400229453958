import axios from "axios"
import router from "@/router"
import { Message } from 'element-ui'
import { refreshToken } from '@/api/login'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    // timeout:10000,
})


let isRefreshing = false;
// 保存需要重新发起请求的队列
let retryRequests = [];

// 请求拦截器
instance.interceptors.request.use(config => {
  const token = localStorage.getItem("save-token");
  if (token) config.headers["token"] = token;
  config.headers["access-control-Allow-Origin"] = '*';
  return config
}, err => {  return Promise.reject(err) })
  
// 响应拦截器
instance.interceptors.response.use(async res => {
  let res_data = res.data
  if (res_data.code > 201) {
      Message.error('请求失败！')
    return false
  } else if(res_data.code == 201 && RegExp(/token已过期/).test(res_data.data)) {
    Message.error('登入过期，请重新登入！')
    localStorage.clear() // 清除所以缓存
    router.push('/login');
    // 刷新token
    // if(!isRefreshing) {
    //   isRefreshing = true;
    //   let { code, data} = await refreshToken({token: localStorage.getItem('save-token')});
    //   if(code == 200) {
    //     localStorage.removeItem('save-token');
    //     localStorage.setItem('save-token', data);
    //     retryRequests.forEach(cb => {
    //       cb(localStorage.getItem('save-token'))
    //     })
    //     retryRequests = [] // 重新请求完清空
    //     const resp = await instance.request(res.config)
    //     return resp.data
    //   }else {
    //     localStorage.clear() // 清除所以缓存
    //     router.push('/login') // 跳转到登录页
    //   }
    //   isRefreshing = false;
    // } else {
    //   return new Promise(resolve => {
    //     // 用函数形式将 resolve 存入，等待刷新后再执行
    //       retryRequests.push(token => {
    //         res.config.headers.token = `${token}`
    //       resolve(instance(res.config))
    //     })
    //   })
    // }
  }  
  return res_data
}, err => { return Promise.reject(err) })
  
export default instance;