
export const reuterData = [
  {
    path: '/map', // 路由路径
    name: 'map', // 路由名称
    component: () => import(/* webpackChunkName: "map" */ '../views/map'), // 对应的vue文件路径
    meta: { // 这个对象是自定义，方便我们放一下东西
      title: '地图',
      icon: 'iconfont icon-ditu',
      rank: 1,
      authority: [1, 2, 3] // 1.管理员 2.区域负责人 3.业务员
    }
  },
  {
    path: '/AccountManag',
    name: 'AccountManag',
    component: () => import(/* webpackChunkName: "AccountManag" */ '../views/AccountManag'),
    meta: {
      title: '账号管理',
      icon: 'iconfont icon-a-2-2',
      rank: 1,
      authority: [1]
    }
  },
  {
    path: '/SalesmanManag',
    name: 'SalesmanManag',
    component: () => import(/* webpackChunkName: "SalesmanManag" */ '../views/SalesmanManag'),
    meta: {
      title: '业务员管理',
      icon: 'iconfont icon-gongpai',
      rank: 1,
      authority: [1, 2]
    }
  },
  {
    path: '/MarkerManag',
    name: 'MarkerManag',
    component: () => import(/* webpackChunkName: "MarkerManag" */ '../views/MarkerManag'),
    meta: {
      title: '标记点管理',
      icon: 'iconfont icon-dangqian',
      rank: 1,
      authority: [1, 2, 3]
    },
  },
  {
    path: '/Approval',
    name: 'Approval',
    meta: {
      title: '标记点审批',
      icon: 'iconfont icon-shenpi',
      newPath: '/Marker/Approval',
      rank: 1,
      child: true,
      father: "Marker",
      authority: [1, 2]
    },
  },
  { 
    path: '/Approval/StayApproved',
    name: 'ApprovalStayApproved',
    component: () => import(/* webpackChunkName: "StayApproved" */ '../views/Approval/StayApproved'),
    meta: {
      title: '待审批',
      icon: 'iconfont icon-daishenpi1',
      rank: 2,
      father: 'Approval',
      authority: [1, 2]
    }
  },
  { 
    path: '/Approval/HasApproved',
    name: 'ApprovalHasApproved',
    component: () => import(/* webpackChunkName: "HasApproved" */ '../views/Approval/HasApproved'),
    meta: {
      title: '已审批',
      icon: 'iconfont icon-yishenpi',
      rank: 2,
      father: 'Approval',
      authority: [1, 2]
    }
  },
  { 
    path: '/Approval/CancelApproved',
    name: 'ApprovalCancelApproved',
    component: () => import(/* webpackChunkName: "CancelApproved" */ '../views/Approval/CancelApproved'),
    meta: {
      title: '取消审批',
      icon: 'iconfont icon-yibohui1',
      rank: 2,
      father: 'Approval',
      authority: [1, 2]
    }
  },
  {
    path: '/Collect',
    name: 'Collect',
    meta: {
      title: '信息收集审批',
      icon: 'iconfont icon-a-shenpi1',
      rank: 1,
      child: true,
      father: "Marker",
      authority: [1, 2]
    },
  },
  { 
    path: '/Collect/StayApproved',
    name: 'CollectStayApproved',
    component: () => import(/* webpackChunkName: "StayApproved" */ '../views/Collect/StayApproved'),
    meta: {
      title: '待审批',
      icon: 'iconfont icon-daishenpi1',
      rank: 2,
      father: 'Collect',
      authority: [1, 2]
    }
  },
  { 
    path: '/Collect/HasApproved',
    name: 'CollectHasApproved',
    component: () => import(/* webpackChunkName: "HasApproved" */ '../views/Collect/HasApproved'),
    meta: {
      title: '已审批',
      icon: 'iconfont icon-yishenpi',
      rank: 2,
      father: 'Collect',
       authority: [1, 2]
    }
  },
  { 
    path: '/Collect/CancelApproved',
    name: 'CollectCancelApproved',
    component: () => import(/* webpackChunkName: "CancelApproved" */ '../views/Collect/CancelApproved'),
    meta: {
      title: '取消审批',
      icon: 'iconfont icon-yibohui1',
      rank: 2,
      father: 'Collect',
       authority: [1, 2]
    }
  },
  {
    path: '/TaskManag',
    name: 'TaskManag',
    meta: {
      title: '任务分配管理',
      icon: 'iconfont icon-renwufenpei-xuanzhong',
      child: true,
      rank: 1,
      authority: [1, 2]
    },
  },
  { 
    path: '/TaskManag/TaksDistribution',
    name: 'TaksDistribution',
    component: () => import(/* webpackChunkName: "TaksDistribution" */ '../views/TaskManag/TaksDistribution'),
    meta: {
      title: '任务分配',
      icon: 'el-icon-share',
      rank: 2,
      father: 'TaskManag',
      authority: [1, 2]
    }
  },
  { 
    path: '/TaskManag/StayVerifyTask',
    name: 'StayVerifyTask',
    component: () => import(/* webpackChunkName: "StayVerifyTask" */ '../views/TaskManag/StayVerifyTask'),
    meta: {
      title: '运行中任务',
      icon: 'el-icon-loading',
      rank: 2,
      father: 'TaskManag',
       authority: [1, 2]
    }
  },
  { 
    path: '/TaskManag/RunningTask',
    name: 'RunningTask',
    component: () => import(/* webpackChunkName: "RunningTask" */ '../views/TaskManag/RunningTask'),
    meta: {
      title: '待确认任务',
      icon: 'el-icon-finished',
      rank: 2,
      father: 'TaskManag',
       authority: [1, 2]
    }
  },
  { 
    path: '/TaskManag/CompletedTask',
    name: 'CompletedTask',
    component: () => import(/* webpackChunkName: "CompletedTask" */ '../views/TaskManag/CompletedTask'),
    meta: {
      title: '已完成任务',
      icon: 'el-icon-check',
      rank: 2,
      father: 'TaskManag',
       authority: [1, 2]
    }
  },
  { 
    path: '/TaskManag/uncompleted',
    name: 'uncompleted',
    component: () => import(/* webpackChunkName: "CompletedTask" */ '../views/TaskManag/uncompleted'),
    meta: {
      title: '未完成任务',
      icon: 'el-icon-close',
      rank: 2,
      father: 'TaskManag',
       authority: [1, 2]
    }
  },
  {
    path: '/Region',
    name: 'Region',
    component: () => import(/* webpackChunkName: "CompletedTask" */ '../views/Region'),
    meta: {
      title: '区域',
      icon: 'iconfont icon-quyuxinxi',
      rank: 1,
       authority: [1, 2]
    },
  },
  {
    path: '/AdminManag',
    name: 'AdminManag',
    component: () => import(/* webpackChunkName: "CompletedTask" */ '../views/AdminManag'),
    meta: {
      title: '行政村管理',
      icon: 'iconfont icon-a-shenpi1',
      rank: 1,
       authority: [1, 2, 3]
    },
  },
  // {
  //   path: '/textView',
  //   name: 'AdmitextViewnManag',
  //   component: () => import(/* webpackChunkName: "CompletedTask" */ '../views/textView'),
  //   meta: {
  //     title: '测试页面',
  //     icon: 'iconfont icon-a-shenpi1',
  //     rank: 1,
  //      authority: [1, 2, 3]
  //   },
  // },
]

